import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import SEO from '../../components/Seo';
const WP_URL = process.env.GATSBY_WP_API;

const ThankYou = ({ profile }) => {
  const [wordpressData, setWordpressData] = useState();
  const [dataLoading, setLoading] = useState(false);
  const fetchConfirmationPageData = async () => {
    setLoading(true);
    return fetch(
      `${WP_URL}/wp-json/acf/v3/order_confirmation/911`
    )
      .then((data) => data.json())
      .then((data) => {
        setWordpressData(data);
        setLoading(false);
        return data;
      })
      .catch((err) => err);
  };
  useEffect(() => {
    fetchConfirmationPageData();
  }, []);

  const bodyContent = wordpressData
    ? `${wordpressData.acf.order_confirmation[0].confirmation_text[0].body[0].body_title}  
      ${profile.firstName}
        ${profile.lastName}`
    : '';

  return !wordpressData ? (
    <div className='text-center' style={{ marginTop: '5rem' }}>
      <i className='fas fa-circle-notch fa-spin' />
    </div>
  ) : (
    <Layout
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className='confirmation-container'>
        <div className='left-section'>
          <div className='text-container'>
            <div className='section-title'>
              <span className='thankyou-span'>
                {
                  wordpressData.acf.order_confirmation[0].confirmation_text[0]
                    .header[0].first_title
                }
              </span>
              <span className='order-confirm-span'>
                {
                  wordpressData.acf.order_confirmation[0].confirmation_text[0]
                    .header[0].second_title
                }
              </span>
            </div>
            <div className='section-body'>
              <span
                className='section-body-title'
                dangerouslySetInnerHTML={{
                  __html: bodyContent,
                }}
              />
              <span className='section-body-content'>
                {
                  wordpressData.acf.order_confirmation[0].confirmation_text[0]
                    .body[0].body_content
                }
              </span>
            </div>
            <div className='section-buttons'>
              <Button
                className='learn-more-button'
                onClick={() => {
                  if (
                    (window.location.href =
                      wordpressData.acf.order_confirmation[0].confirmation_text[0].buttons[0].learn_more_button[0].external_link)
                  ) {
                    return (window.location.href =
                      wordpressData.acf.order_confirmation[0].confirmation_text[0].buttons[0].learn_more_button[0].external_link);
                  } else if (
                    (window.location.href =
                      wordpressData.acf.order_confirmation[0].confirmation_text[0].buttons[0].learn_more_button[0].internal_link)
                  ) {
                    return (window.location.href =
                      wordpressData.acf.order_confirmation[0].confirmation_text[0].buttons[0].learn_more_button[0].external_link);
                  } else {
                    return (window.location.href =
                      wordpressData.acf.order_confirmation[0].confirmation_text[0].buttons[0].learn_more_button[0].external_link);
                  }
                }}
              >
                {
                  wordpressData.acf.order_confirmation[0].confirmation_text[0]
                    .buttons[0].learn_more_button[0].title
                }
              </Button>
              <Button
                className='continue-button'
                onClick={() => navigate('/dashboard')}
              >
                <i className='fas fa-arrow-right' />
              </Button>
            </div>
          </div>
        </div>
        <div className='right-section'>
          <img
            src={wordpressData.acf.order_confirmation[0].image.url}
            alt='info'
          />
        </div>
      </div>
      <SEO title='Order Confirmed' />
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  profile: state.login.auth && state.login.auth.profile,
});

export default connect(mapStateToProps, null)(ThankYou);
